<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="black"
          tile

          contain
        >
          <v-img
            src="cristaltec-logo-no-name.png"
            max-height="25"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="text-h4"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />
    <side-menu-entity-admin v-if="user && user.user_role_id === 4" />
    <side-menu-admin v-else-if="user && user.user_role_id === 1" />
    <side-menu-stock-manager v-else-if="user && user.user_role_id === 2" />
    <side-menu-operator v-else-if="user && user.user_role_id === 3" />
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {
  mapState,
} from 'vuex'
import SideMenuEntityAdmin from '@/views/dashboard/dashboardComponents/core/EntityAdmin/SideMenu'
import SideMenuStockManager from '@/views/dashboard/dashboardComponents/core/StockManager/SideMenu'
import SideMenuAdmin from '@/views/dashboard/dashboardComponents/core/Admin/SideMenu'
import SideMenuOperator from '@/views/dashboard/dashboardComponents/core/Operator/SideMenu'

export default {
  name: 'DashboardCoreDrawer',
  components: { SideMenuOperator, SideMenuAdmin, SideMenuStockManager, SideMenuEntityAdmin },
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['barColor', 'barImage']),
    drawer: {
      get () {
        return this.$store.state.drawer
      },
      set (val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },
    user () {
      return this.$store.getters.getUserLogged
    },
    profile () {
      return {
        avatar: true,
        title: this.$t('avatar'),
      }
    },
  },

  methods: {
    mapItem (item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      }
    },
  },
}
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
