<template>
  <v-list
    expand
    nav
  >
    <div />

    <template v-for="(item, i) in items">
      <base-item-group
        v-if="item.children"
        :key="`group-${i}`"
        :item="item"
      >
        <!--  -->
      </base-item-group>

      <base-item
        v-else
        :key="`item-${i}`"
        :item="item"
      />
    </template>
    <div />
  </v-list>
</template>

<script>

export default {
  name: 'SideMenuOperator',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: 'mdi-account',
        title: 'Orders',
        to: '/orders',
      },
    ],
  }),
}
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
